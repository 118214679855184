import {
  IMemberRolePickerProps,
  IRoleOption,
  MemberRolePicker,
} from "src/components/Organizations/Organization/MemberRolePicker"
import { HomeRole } from "src/data/organizations/types/organizationMemberTypes"
import { langKeys } from "src/i18n/langKeys"

interface IHomeRoleOptions extends IRoleOption<HomeRole> {}
const homeRoleOptions: IHomeRoleOptions[] = [
  {
    label: langKeys.organizations_role_home_admin,
    description: langKeys.organizations_home_admin_role_short_description,
    role: HomeRole.ADMIN,
  },
  {
    label: langKeys.organizations_role_home_member,
    description: langKeys.organizations_home_member_role_short_description,
    role: HomeRole.MEMBER,
  },
  {
    label: langKeys.organizations_role_home_responder,
    description: langKeys.organizations_home_responder_role_short_description,
    role: HomeRole.RESPONDER,
  },
]

export function HomeRolePicker(
  props: Omit<IMemberRolePickerProps<HomeRole>, "options">
) {
  return <MemberRolePicker {...props} options={homeRoleOptions} />
}
